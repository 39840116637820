@import "@/assets/scss/main.scss";

.profilInfo {
    display: flex;
    gap: 90px;
}

.aufgabe {
    color: $elsi-color-aufgabe;
}

.headerCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 20px;
    width: 100%;

    background: #FFFFFF;
    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .headline {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height, or 114% */

        }
    }

    .info {
        box-sizing: border-box;
        background-color: $elsi-color-bar-bg;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 85%;
        border:none;
        border-left: solid;
        border-left-width: 10px;
        border-left-color: var(--border-color);
        line-height: 25px;

        .icon {
            margin-top: 3px;
            color: #4BB3D4;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    .survey {
        width: 100%;
    }

    .icon-item {
        background-color: #ECF0F3;
        margin-left: 1rem;
        border-radius: 3px;

        &:hover {
            background-color: white;
        }

    }

}
