@import "@/assets/scss/main.scss";

section#sidebar {
    background-color: white;
    height: fit-content;
    border-radius: 6px;
    width: 100px;
    min-width: 100px;
    padding: 32px 0 32px 0;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white;
    }

    .sidebar-icons {
        display: flex;
        justify-content: center;

        .filter-bar {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .filter-category {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 20px;
            }

            .icon {
                scale: 1;
                display: flex;

            }

            .icon-question {
                scale: 1.25;
                display: flex;

            }
        }
    }
}

section#sidebar-glossar {
    background-color: white;
    height: fit-content;
    border-radius: 6px;
    width: 310px;
    min-width: 100px;
    padding: 32px 0 32px 0;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white;
    }

    .sidebar-icons {
        display: flex;
        justify-content: center;

        .filter-bar {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .filter-category {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 20px;
            }

            .list-item {
                display: flex;
                gap: 20px;

            }

            .icon {
                scale: 1;
                display: flex;

            }

            .icon-question {
                scale: 1.25;
                display: flex;

            }
        }
    }
}


.icon {
    display: flex;
    align-self: center;
}

.list-group-item {
    border: none;
    width: fit-content;
}


[click] {
    cursor: pointer;
}
